import { createApp, h } from 'vue'
import { turbolinksAdapterMixin } from 'vue-turbolinks'

import QuestionsAnswers from "./index.vue";
import axios from "../../plugins/axios";

document.addEventListener("turbo:load", () => {
  const el = document.getElementById("questions-answers");
  if (el) {
    const csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')

    const props = JSON.parse(el.getAttribute("data"));

    const app = createApp({
      mixins: [turbolinksAdapterMixin],
      render () { return h(QuestionsAnswers, props) }
    });
    app.use(axios, {
      csrfToken
    })
    app.mount(el)
  }
});
