import _url from "url";

export const getUrl = () => _url.parse(window.location.href, true);
export const updateHistory = (page, replaceState = false) => {
  let url = getUrl();
  url = {
    ...url,
    search: null,
    query: {
      ...url.query,
      page,
    },
  };
  if (page === "all") delete url.query.page;
  history[replaceState ? "replaceState" : "pushState"](
    { turbolinks: false, page },
    "",
    _url.format(url)
  );
};

// Audio Player
const addLeadingZero = (number) => (number > 9 ? number : `0${number}`);
export const getTimeForSeconds = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds - minutes * 60;
  return `${minutes}:${addLeadingZero(seconds)}`;
};
