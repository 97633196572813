<template>
  <div class="integration-code">
    <p class="info">Position: </p>
    <template v-for="item in types" :key="item">
      <div class="field field-select">
        <div class="check-round">
          <input
            v-bind:id="item"
            type="radio"
            v-model="type"
            v-bind:value="item"
          />
          <label v-bind:for="item"></label>
        </div>
        <span>{{ texts[item] }}</span>
      </div>
    </template>
    <div class="integration-description" v-if="type === 'topBar' || type === 'bottomCorner'">
      <p class="info">{{ texts["descriptionLabel"] }}</p>
      <div class="field emoji speech">
        <input
          class="input-text shadow"
          v-bind:placeholder="texts['descriptionPlaceholder']"
          type="text"
          v-model="description"
        />
      </div>
    </div>
    <CodeBox
      v-bind:cdnPath="cdnPath"
      v-bind:description="description"
      v-bind:slug="slug"
      v-bind:texts="texts"
      v-bind:type="type"
    />
  </div>
</template>

<script>
import CodeBox from "./code_box.vue";

const types = ["topBar", "bottomCorner", "manualTrigger"];

export default {
  components: { CodeBox },
  props: ["cdnPath", "slug", "texts"],
  data() {
    return {
      type: types[0],
      description: this.texts["descriptionDefault"],
      types,
    };
  },
  methods: {
    onSwitch(type) {},
  },
  provide() {
    return {
      texts: this.texts,
    };
  },
};
</script>
