const clickOutsideDirective = {
  beforeMount: (el, binding) => {
    el.event = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.body.addEventListener('mousedown', el.event)
  },
  unmounted: function (el) {
    document.body.removeEventListener('mousedown', el.event)
  },
}

export default clickOutsideDirective
