<template>
  <div class="integrator-code-box" v-on:click="selectAll">
    <small>{{ texts["selectHint"] }}</small>
    <div class="box-shadow">
      <pre><code ref="code">{{code}}</code></pre>
    </div>
  </div>
</template>

<script>
import hljs from "highlightjs";
import getCode from "./code";

hljs.configure({
  languages: ["html", "javascript"],
});

export default {
  beforeCreate() {
    this.renderHighlightedCode = function () {
      this.$refs.code.textContent = this.code;
      hljs.highlightBlock(this.$refs.code);
    };
  },
  props: ["cdnPath", "description", "slug", "texts", "type"],
  computed: {
    code() {
      return getCode({
        cdnPath: this.cdnPath,
        slug: this.slug,
        type: this.type,
        description: this.description,
      });
    },
  },
  methods: {
    selectAll() {
      window.getSelection().selectAllChildren(this.$refs.code);
    },
  },
  mounted() {
    this.renderHighlightedCode();
  },
  updated() {
    this.renderHighlightedCode();
  },
};
</script>
