import { createApp } from 'vue'
import { turbolinksAdapterMixin } from 'vue-turbolinks'
import Recorder from "opus-recorder";
import WaveSurfer from "wavesurfer.js";
import encoderPath from "opus-recorder/dist/encoderWorker.min.js";
import { getTimeForSeconds } from "../helpers";

const recorderConfig = { encoderPath, encoderApplication: 2048 };

document.addEventListener("turbo:load", () => {
  const el = document.getElementById("vue-reply");
  if (!el) return;

  const payload = JSON.parse(el.dataset.payload);
  const app = createApp({
    mixins: [turbolinksAdapterMixin],
    data () {
      return {
        activeMode: "voice",
        voiceState: "ready",
        playState: "pause",
        recording: {},
        timer: {
          interval: null,
          startTimestamp: null,
        },
        time: "0:00",
        playtime: "0:00",
        duration: "0:00",
        wavesurfer: null,
        ...payload,
      }
    },
    methods: {
      async onRecord() {
        this.recorder = new Recorder(recorderConfig);
        this.recorder.ondataavailable = this.onDataAvailable;
        await this.recorder.start();

        this.timer = {
          interval: setInterval(() => {
            const differenceInMs =
              new Date().getTime() - this.timer.startTimestamp;
            const totalSeconds = Math.floor(differenceInMs / 1000);
            this.time = getTimeForSeconds(totalSeconds);
          }, 1000),
          startTimestamp: new Date().getTime(),
        };

        this.voiceState = "recording";
      },
      resetTimer() {
        clearInterval(this.timer.interval);
        this.timer.startTimestamp = null;
        this.time = "0:00";
      },
      async onStop() {
        await this.recorder.stop();
        this.resetTimer();

        this.voiceState = "recorded";
      },
      async onReset() {
        await this.recorder.close();

        this.resetTimer();

        this.wavesurfer.empty();
        this.wavesurfer.load();
        this.wavesurfer = null;

        this.recording = {};
        this.voiceState = "ready";
      },
      onPlay() {
        this.wavesurfer.play();
      },
      onPause() {
        this.wavesurfer.pause();
      },
      onSubmit() {
        if (
          this.voiceAnswersEnabled &&
          this.activeMode === "voice" &&
          this.recording.file
        ) {
          const container = new DataTransfer();
          container.items.add(this.recording.file);
          this.$refs.voiceInput.files = container.files;
        }

        this.$refs.recaptchaData.value =
          document.getElementById("g-recaptcha-response").value ||
          document.getElementById("g-recaptcha-response-data-answer").value;

        return true;
      },
      onDataAvailable(typedArray) {
        const time = new Date().getTime();
        const dataBlob = new Blob([typedArray], { type: "audio/ogg" });
        this.recording = {
          url: URL.createObjectURL(dataBlob),
          file: new File([typedArray], `${time}.opus`, {
            type: "audio/ogg",
            lastModified: time,
          }),
        };
      },
    },
    updated: function () {
      let playtimeInterval;
      this.$nextTick(function () {
        if (this.wavesurfer === null && this.$refs.wave && this.recording.url) {
          this.wavesurfer = WaveSurfer.create({
            container: this.$refs.wave,
            barWidth: 2,
            barHeight: 2,
            barGap: null,
            cursorWidth: 1,
            height: 64,
            normalize: true,
            progressColor: "#589ae8",
            waveColor: "#727272",
          });

          this.wavesurfer.on("finish", () => {
            this.playState = "pause";
            clearInterval(playtimeInterval);
          });
          this.wavesurfer.on("pause", () => {
            this.playState = "pause";
            clearInterval(playtimeInterval);
          });
          this.wavesurfer.on("play", () => {
            this.playState = "playing";
            playtimeInterval = setInterval(() => {
              this.playtime = getTimeForSeconds(
                Math.floor(this.wavesurfer.getCurrentTime())
              );
            }, 100);
          });
          this.wavesurfer.on(
            "ready",
            () =>
              (this.duration = getTimeForSeconds(
                Math.floor(this.wavesurfer.getDuration())
              ))
          );
          this.wavesurfer.on("seek", () => this.wavesurfer.play());
          this.wavesurfer.load(this.recording.url);
        }
      });
    },
  });
  app.mount(el)
});
