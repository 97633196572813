const getSnippet = ({ slug, type, description }) => {
  const snippets = {
    topBar: `meenit.registerComponent({
      type: "top-bar",
      slug: "${slug}",
      description: "${description}",
    });`,
    bottomCorner: `meenit.registerComponent({
      type: "bottom-corner",
      slug: "${slug}",
      description: "${description}",
    });`,
    manualTrigger: `meenit.registerLinks({
      slug: "${slug}",
      selector: "[data-meenit-activate]",
    });`,
  };
  return snippets[type];
};

export default ({
  cdnPath,
  slug,
  type,
  description,
}) => `<script async defer src="${cdnPath}"
  onload="this.onload=function(){};initMeenitIntegrator()"
  onreadystatechange="if (this.readyState === 'complete') this.onload()">
</script>
<script>
  function initMeenitIntegrator() {
    var meenit = new MeenitIntegrator();
    ${getSnippet({ slug, type, description })}
  }
</script>
`;
