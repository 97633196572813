import { createApp } from 'vue'
import { turbolinksAdapterMixin } from 'vue-turbolinks'

const isSupported = () => {
  const div = document.createElement('div')
  return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div))
    && 'FormData' in window && 'FileReader' in window
}

document.addEventListener('turbo:load', () => {
  const el = document.querySelector('.vue-file-upload')
  if (el) {
    const app = createApp({
      mixins: [turbolinksAdapterMixin],
      components: {},
      data () {
        return {
          isOver: false,
          isDragging: false,
          src: null
        }
      },
      methods: {
        onChange (e) {
          const {files} = e.currentTarget
          const reader = new FileReader()
          reader.onload = e => this.src = e.target.result

          if (files && files[0]) {
            reader.readAsDataURL(files[0])
          } else {
            this.src = null
          }
        },
        onDragEnterDocument (e) {
          this.isDragging = true
        },
        onDragLeaveDocument (e) {
          // if cursor is outside of 'window'
          if (!e.clientX && !e.clientY) {
            this.isDragging = false
          }
        },
        onDragEnter (e) {
          this.isOver = true
        },
        onDragLeave (e) {
          this.isOver = false
        },
        onDrop (e) {
          this.isOver = false
          this.isDragging = false
        },
        onReset (e) {
          this.$refs.fileField.value = null
          this.src = null
        }
      },
      beforeUnmount () {
        document.body.removeEventListener('dragstart', this.onDragEnterDocument)
        document.body.removeEventListener('dragleave', this.onDragLeaveDocument)
      },
      beforeMount () {
        if (isSupported) {
          document.body.addEventListener('dragenter', this.onDragEnterDocument)
          document.body.addEventListener('dragleave', this.onDragLeaveDocument)
        }
      },
      mounted () {
        const {src} = this.$refs.fileField.dataset
        if (src) {
          this.src = src
        }
      }
    })
    app.mount(el)
  }
})