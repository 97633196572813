<template>
  <div class="pagination" v-if="pages.total_pages > 1">
    <ul class="pagination-list">
      <li v-if="previousPage"
          v-on:click.stop.prevent="onPaginate(previousPage)">{{texts.pagination.prev}}</li>
      <li v-for="page in pagesWindow"
          v-bind:key="page"
          v-on:click.stop.prevent="onPaginate(page)"
          v-bind:class="{active: page == pages.current_page}">{{page}}</li>
      <li v-if="nextPage"
          v-on:click.stop.prevent="onPaginate(nextPage)">{{texts.pagination.next}}</li>
    </ul>
    <a class="show-all" href="" v-on:click.stop.prevent="onPaginate('all')">{{showAll}}</a>
  </div>
</template>

<script>
import {updateHistory} from '../../helpers'
import _url from 'url'

/* WINDOW_RANGE defines the number of pages shown before and after the current page
 *
 * Examples:
 * WINDOW_RANGE: 2, currentPage: 4 => [2,3,4,5,6]
 * WINDOW_RANGE: 3, currentPage: 4 => [1,2,3,4,5,6,7]
 */
const WINDOW_RANGE = 2
const buildPagesWindow = (currentPage, totalPages) => {
  const pagesWindow = [currentPage]
  for (let i = 1; i <= WINDOW_RANGE; i++) {
    if (currentPage + i <= totalPages) {
      pagesWindow.push(currentPage + i)
    }
    if (currentPage - i > 0) {
      pagesWindow.unshift(currentPage - i)
    }
  }
  return pagesWindow
}

export default {
  props: ['pages'],
  computed: {
    nextPage() {
      const {current_page: currentPage, total_pages: totalPages} = this.pages
      return currentPage + 1 <= totalPages ? currentPage + 1 : null
    },
    previousPage() {
      const {current_page: currentPage} = this.pages
      return currentPage - 1 > 0 ? currentPage - 1 : null
    },
    pagesWindow() {
      const {current_page: currentPage, total_pages: totalPages} = this.pages
      return buildPagesWindow(currentPage, totalPages)
    },
    showAll() {
      return this.texts.pagination.show_all.replace(
        '%{count}',
        this.pages.total_count
      )
    }
  },
  methods: {
    onPaginate(page) {
      updateHistory(page)
      this.$emit('onPaginate', page)
    }
  },
  mounted() {
    history.replaceState(
      {turbolinks: false, page: this.pages.current_page},
      '',
      window.location.href
    )

    window.addEventListener('popstate', e => {
      e.preventDefault()
      if (e.state && e.state.page) {
        this.$emit('onPaginate', e.state.page)
      }
    })
  },
  inject: ['texts']
}
</script>
