import "@hotwired/turbo-rails"

import "./components/clipboard_action";
import "./components/color_picker";
import "./components/cookie_notice";
import "./components/file_upload";
import "./components/floating_notes";
import "./components/message_box";
import "./components/mobile_menu";
import "./components/optional_description";
import "./components/profile_links";
import "./components/reply";
import "./questions/answers";
import "./questions/tools/integrator";

Turbo.start()
