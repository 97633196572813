import { createApp } from 'vue'
import { turbolinksAdapterMixin } from 'vue-turbolinks'

document.addEventListener('turbo:load', () => {
  const el = document.querySelector('.vue-profile-links')
  if (el) {
    const profile = JSON.parse(el.dataset.profile)
    const links_attributes = JSON.parse(el.dataset.linksAttributes)
    links_attributes.forEach(link => (link._destroy = null))
    profile.links_attributes = links_attributes
    const app = createApp({
      mixins: [turbolinksAdapterMixin],
      data () {
        return {
          profile
        }
      },
      methods: {
        addLink () {
          this.profile.links_attributes.push({
            id: null,
            name: '',
            url: '',
            _destroy: null
          })
        },
        removeLink (index) {
          const link = this.profile.links_attributes[index]

          if (link.id === null) {
            this.profile.links_attributes.splice(index, 1)
          } else {
            this.profile.links_attributes[index]._destroy = '1'
          }
        },
        undoRemove (index) {
          this.profile.links_attributes[index]._destroy = null
        },
        getInputName (type, index) {
          return `question[profile_attributes][links_attributes][${index}][${type}]`
        }
      }
    })
    app.mount(el)
  }
})
