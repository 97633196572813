<template>
  <div class="meenit-answer">
    {{texts.noAnswers}}
  </div>
</template>

<script>
export default {
  inject: ['texts'],
  props: ['item']
}
</script>
