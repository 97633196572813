import { createApp } from 'vue'
import { turbolinksAdapterMixin } from 'vue-turbolinks'
import axios from "../plugins/axios";

document.addEventListener('turbo:load', () => {
  const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')

  const el = document.querySelector('.vue-floating-notes')
  if (el) {
    const app = createApp({
      mixins: [turbolinksAdapterMixin],
      data () {
        return {
          isLoading: false,
          isOpen: false,
          adminToken: null,
          notes: ''
        }
      },
      mounted () {
        const {form, textArea} = this.$refs
        const {adminToken, notes} = textArea.dataset
        if (adminToken) {
          this.adminToken = adminToken
        }
        if (notes && notes !== '') {
          this.notes = notes
        }

        form.onsubmit = e => {
          e.preventDefault()
          this.saveNotes()
          return false
        }
      },
      methods: {
        saveNotes () {
          const body = {
            question: {
              admin_token: this.adminToken,
              notes: this.notes
            }
          }
          this.isLoading = true
          this.$axios.patch(this.$refs.form.action, body).then(
            response => {
              this.isLoading = false
            },
            response => {
              this.isLoading = false
            }
          )
        }
      }
    })
    app.use(axios, {
      csrfToken
    })
    app.mount(el)
  }
})
