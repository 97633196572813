<template>
  <div class="answers">
    <template v-if="answers.length">
      <Pagination v-bind:pages="pages" v-on:onPaginate="onPaginate" />
      <Item
        v-for="answer in answers"
        v-bind:item="answer"
        v-bind:key="answer.id"
        v-on:onDelete="onDelete"
        v-on:onFavorite="onFavorite"
        v-on:onPlay="onPlay"
        ref="items"
      />
      <Pagination v-bind:pages="pages" v-on:onPaginate="onPaginate" />
    </template>
    <template v-else>
      <ItemEmpty />
    </template>
  </div>
</template>

<script>
import { adjust, findIndex, propEq, reject } from "ramda";
import _url from "url";
import { getUrl, updateHistory } from "../../helpers";
import Item from "./item.vue";
import ItemEmpty from "./item_empty.vue";
import Pagination from "./pagination.vue";

const PER_PAGE = 50;
const byId = propEq("id");
const toggleFavorite = (item) => ({
  ...item,
  favorite: !item.favorite,
});

export default {
  components: { Item, ItemEmpty, Pagination },
  props: ["initialAnswers", "initialPages", "answersEndpoint", "texts"],
  methods: {
    onDelete({ id, url }) {
      if (window.confirm(this.texts.youSure)) {
        this.answers = reject(byId(id), this.answers);
        let { current_page, total_pages } = this.pages;
        const willCurrentPageBeEmpty =
          current_page > 1 &&
          current_page === total_pages &&
          this.answers.length === 0;

        if (willCurrentPageBeEmpty) {
          current_page--;
          updateHistory(current_page, true);
        }

        this.$axios.delete(url).then(() => this.onPaginate(current_page));
      }
    },
    onFavorite({ id, url }) {
      const index = findIndex(byId(id), this.answers);
      this.answers = adjust(index, toggleFavorite, this.answers);
      this.$axios.patch(url);
    },
    onPaginate(page) {
      const params = { page, per_page: getUrl().query.per_page || PER_PAGE };
      this.$axios.get(this.answersEndpoint, { params }).then((res) => {
        this.answers = res.body.data;
        this.pages = res.body.pages;
      });
    },
    onPlay(id) {
      this.$refs.items.forEach(({ item, stop }) => {
        if (item.id !== id) {
          stop();
        }
      });
    },
  },
  data() {
    return {
      answers: this.initialAnswers,
      pages: this.initialPages,
    };
  },
  provide() {
    return {
      texts: this.texts,
    };
  },
};
</script>
