import { createApp } from 'vue'
import { turbolinksAdapterMixin } from 'vue-turbolinks'

document.addEventListener('turbo:load', () => {
  const el = document.querySelector('.vue-optional-description')
  if (el) {
    const description = JSON.parse(el.dataset.descriptionValue)
    const app = createApp({
      mixins: [turbolinksAdapterMixin],
      data () {
        return {
          description,
          isOpen: false
        }
      }
    })
    app.mount(el)
  }
})
