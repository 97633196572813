import { createApp } from 'vue'
import { turbolinksAdapterMixin } from 'vue-turbolinks'

document.addEventListener('turbo:load', () => {
  const el = document.querySelector('.vue-message-box')
  const TIME_OUT = 3000
  if (el) {
    const app = createApp({
      mixins: [turbolinksAdapterMixin],
      data: {
        visible: true
      },
      mounted() {
        setTimeout(() => (this.visible = false), TIME_OUT)
      }
    })
    app.mount(el)
  }
})
