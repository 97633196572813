import { createApp } from 'vue'
import { turbolinksAdapterMixin } from 'vue-turbolinks'
import { Sketch, create } from '@ckpack/vue-color';
import {clickOutside} from '../directives'

const defaultHex = '#000000'

document.addEventListener('turbo:load', () => {
  const el = document.querySelector('.vue-color-picker')
  if (el) {
    const app = createApp({
      mixins: [turbolinksAdapterMixin],
      data () {
        return {
          colors: {hex: '', a: 1},
          visible: false
        }
      },
      methods: {
        clickOutside (event) {
          this.visible = false
        },
        updateValue (values) {
          const colors = {
            ...values,
            a: 1
          }
          this.colors = colors
        }
      },
      mounted () {
        const {value} = this.$refs.colorField.dataset
        if (value && value !== '') {
          this.colors = {
            hex: this.$refs.colorField.dataset.value,
            a: 1
          }
        }
      }
    });
    app.use(create({
      components: [Sketch]
    }))
    app.directive('click-outside', clickOutside)
    app.mount(el)
  }
})