import { createApp } from 'vue'
import { turbolinksAdapterMixin } from 'vue-turbolinks'
import Cookies from 'js-cookie'

const COOKIE_NAME = 'cookie_notice_dismissed'

document.addEventListener('turbo:load', () => {
  const el = document.getElementById('cookie-notice')
  if (el) {
    const app = createApp({
      mixins: [turbolinksAdapterMixin],
      data () {
        return {
          isDismissed: Boolean(Cookies.get(COOKIE_NAME))
        }
      },
      methods: {
        onDismiss () {
          this.isDismissed = true
          Cookies.set(COOKIE_NAME, true)
        }
      }
    });
    app.mount(el)
  }
})
