import { createApp } from 'vue'
import { turbolinksAdapterMixin } from 'vue-turbolinks'

document.addEventListener('turbo:load', () => {
  const el = document.querySelector('.vue-mobile-menu')
  if (el) {
    const app = createApp({
      mixins: [turbolinksAdapterMixin],
      data () {
        return {
          isOpen: false
        }
      }
    })
    app.mount(el)
  }
})
