<template>
  <div class="meenit-answer" v-bind:class="{ 'is-favorite': item.favorite }">
    <p class="meenit-answer-date">{{ item.formatted_created_at }}</p>
    <template v-if="item.voice_recording_attached">
      <div class="player">
        <div class="main">
          <div class="controls">
            <template v-if="playState === 'pause'">
              <button v-on:click.prevent="onPlay">
                <img alt="Play" src="#"> <!-- TODO -->
              </button>
            </template>
            <template v-else>
              <button v-on:click.prevent="onPause">
                <img alt="Pause" src="#"> <!-- TODO -->
              </button>
            </template>
          </div>
          <div class="recording">
            <div ref="wave" class="wave"></div>
          </div>
        </div>
        <div class="sub">
          <div class="controls"></div>
          <div class="duration">
            {{ playState === "pause" ? duration : playtime }}
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <span style="white-space: pre-wrap">{{ item.content }}</span>
    </template>

    <div class="actions">
      <div
        v-on:click.stop.prevent="
          $emit('onDelete', { id: item.id, url: item.delete_endpoint })
        "
        class="field emoji emoji-link waste"
      >
        <a href="deletelink" data-turbo="false">{{ texts.delete }}</a>
        <span class="tooltip">{{ texts.delete }}</span>
      </div>
      <div
        v-bind:class="{ 'is-favorite': item.favorite }"
        class="field emoji emoji-link star"
      >
        <a
          v-on:click.stop.prevent="
            $emit('onFavorite', { id: item.id, url: item.favorite_endpoint })
          "
          href="favoritelink"
          data-turbo="false"
        >
          {{ toggleFavoriteText }}
        </a>
        <span class="tooltip">{{ toggleFavoriteText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import WaveSurfer from "wavesurfer.js";
import { getTimeForSeconds } from "../../helpers";

export default {
  data() {
    const { favorite, unfavorite } = this.texts;
    return {
      toggleFavoriteText: this.item.favorite ? unfavorite : favorite,
      playState: "pause",
      playtime: "0:00",
      duration: "0:00",
    };
  },
  inject: ["texts"],
  props: ["item"],
  methods: {
    onPlay() {
      this.wavesurfer.play();
    },
    onPause() {
      this.wavesurfer.pause();
    },
    stop() {
      if (this.wavesurfer) {
        this.wavesurfer.stop();
      }
    },
  },
  mounted() {
    let playtimeInterval;

    if (this.$refs.wave) {
      this.wavesurfer = WaveSurfer.create({
        container: this.$refs.wave,
        barWidth: 2,
        barHeight: 2,
        barGap: null,
        cursorWidth: 1,
        height: 64,
        normalize: true,
        progressColor: "#589ae8",
        waveColor: "#727272",
      });
      this.wavesurfer.on("finish", () => {
        this.playState = "pause";
        clearInterval(playtimeInterval);
      });
      this.wavesurfer.on("pause", () => {
        this.playState = "pause";
        clearInterval(playtimeInterval);
      });
      this.wavesurfer.on("play", () => {
        this.playState = "playing";
        this.$emit("onPlay", this.item.id);
        playtimeInterval = setInterval(() => {
          this.playtime = getTimeForSeconds(
            Math.floor(this.wavesurfer.getCurrentTime())
          );
        }, 100);
      });
      this.wavesurfer.on("ready", () => {
        this.duration = getTimeForSeconds(
          Math.floor(this.wavesurfer.getDuration())
        );
      });
      this.wavesurfer.on("seek", (seek) => {
        if (seek > 0) {
          this.wavesurfer.play();
        }
      });
      this.wavesurfer.load(this.item.voice_recording_src);
    }
  },
};
</script>
