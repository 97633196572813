/* eslint no-console: 0 */

import { createApp } from 'vue'
import { turbolinksAdapterMixin } from 'vue-turbolinks'
import useClipboard from 'vue-clipboard3'

document.addEventListener('turbo:load', () => {
  const SUCCESS_TIME_OUT = 1000
  const els = document.querySelectorAll('.vue-clipboard-action')
  for (let el of els) {
    const app = createApp({
      mixins: [turbolinksAdapterMixin],
      data () {
        return {
          tooltip: null,
          url: ''
        }
      },
      methods: {
        async onCopy () {
          const {toClipboard} = useClipboard()
          try {
            await toClipboard(this.url)
            const {success, tooltip} = this.$refs.tooltip.dataset
            this.tooltip = success
            setTimeout(() => this.tooltip = tooltip, SUCCESS_TIME_OUT)
          } catch (e) {
          }
        }
      },
      mounted () {
        const {tooltip, url} = this.$refs.tooltip.dataset
        this.tooltip = tooltip
        this.url = url
      }
    });
    app.mount(el)
  }
})
