import axios from 'axios'

export default {
    install: (app, options) => {
        app.config.globalProperties.$axios = axios.create({
            headers: {
                "Accept": "application/json",
                "X-CSRF-Token": (options || {}).csrfToken
            }
        })
    }
}
