import {createApp, h} from 'vue'
import { turbolinksAdapterMixin } from 'vue-turbolinks'

import Integrator from './index.vue'

document.addEventListener('turbo:load', () => {
  const el = document.getElementById('questions-tools-integrator')
  if (el) {
    const props = JSON.parse(el.getAttribute('data'))
    const app = createApp({
      mixins: [turbolinksAdapterMixin],
      render () { return h(Integrator, props) }
    })
    app.mount(el)
  }
})
